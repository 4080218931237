import React from 'react';
import styled from "styled-components";
import {Link} from "gatsby";
import LogoMain from "../images/logoMain.svg"
import Logo from "../images/logo.svg"

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: ${props => props.type === "main" ? "85px" : "23px"};
  margin-bottom: ${props => props.type === "main" ? "180px" : "62px"};
  width: 1110px;
  align-self: center;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    margin-bottom: ${props => props.type === "main" ? "150px" : "52px"};
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    margin-bottom: ${props => props.type === "main" ? "120px" : "42px"};
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    margin-bottom: ${props => props.type === "main" ? "90px" : "32px"};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12.5px;
  @-moz-document url-prefix() {{font-size: 9.3px;}}
  color: #FFFFFF;
  margin-right: 85px;
  @media screen and (max-width: 1100px) {
    margin-right: 49px;
    font-size: 10.8px;
  }
  @media screen and (max-width: 960px) {
    font-size: 9.7px;
    margin-right: 42px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 30px;
    font-size: 8.6px;
    @-moz-document url-prefix() {{font-size: 4.95px;}}
  }
`;

const NavLink = styled(Link)`
  font-family: Montserrat;
  font-size: 14px;
  color: ${props => props.type === "main" ? "#FFFFFF" : "#212939"};
  margin-right: 22px;
  text-decoration: none;
  margin-top: 30px;

  &:hover {
    text-decoration: ${props => props.type === "main" ? "underline" : "none"};
    color: ${props => props.type === "main" ? "#FFFFFF" : "#4C77FF"};
  }
  @media screen and (max-width: 1100px) {
    margin-right: 18px;
    font-size: 13px;
    margin-top: 25px;
  }
  @media screen and (max-width: 960px) {
    margin-right: 15px;
    font-size: 11px;
    margin-top: 22px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 13px;
    font-size: 9px;
    margin-top: 20px;
  }
`;

const Mailto = styled.a`
  font-family: Montserrat;
  text-decoration: none;
  position: absolute;
  top: -16px;
  right: 25px;
  font-size: 14px;
  color: ${props => props.type === "main" ? "#FFFFFF" : "#212939"};
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 1100px) {
    right: 56px;
    font-size: 13px;
    margin-top: 17px;
  }
  @media screen and (max-width: 960px) {
    right: 36px;
    font-size: 11px;
    margin-top: 15px;
  }
  @media screen and (max-width: 800px) {
    right: 17px;
    font-size: 9px;
    margin-top: 11px;
  }
`;

const Slogan = styled.div`
  color: ${props => props.type === "main" ? "#FFFFFF" : "#4C77FF"};
`;

const Image = styled.img`
  margin-bottom: 5px;
  width: 342px;
  @media screen and (max-width: 1100px) {
    width: 295px;
  }
  @media screen and (max-width: 960px) {
    width: 265px;
  }
  @media screen and (max-width: 800px) {
    width: 235px;
  }
`;

const Header = ({type}) => {
    return (
        <HeaderContainer type={type}>
            <LogoContainer>
                {type === "main" ? <Image src={LogoMain} alt="logo"/>
                    : <Link to="/">
                        <Image src={Logo} alt="logo"/>
                    </Link>}
                <Slogan type={type}>
                    Быть надежным. Думать о будущем. Внедрять новое
                </Slogan>
            </LogoContainer>
            <NavLink type={type} to="/about">
                О компании
            </NavLink>
            <NavLink type={type} to="/projects">
                Проекты
            </NavLink>
            <NavLink type={type} to="/geography">
                География деятельности
            </NavLink>
            <NavLink type={type} to="/partners">
                Партнеры
            </NavLink>
            <NavLink type={type} to="/vacancies">
                Вакансии
            </NavLink>
            <NavLink type={type} to="/contacts">
                Контакты
            </NavLink>
            <Mailto type={type} href="mailto:kadry@pp-proekt.ru">
                kadry@pp-proekt.ru
            </Mailto>
        </HeaderContainer>
    )
}

export default Header;